import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import T from "i18n-react"
import LazyLoad from "react-lazyload"
import { urlPrettify } from "../../../common/tool/Formatter"
import styles from "./popular-cities.less"
import StylesBase from "../../../assets/less/base.less"
import { fixImageUrl, _isEmpty } from "../../../common/tool/util"
import { isT4Fes } from "../../../../config"

const Component = props => {
    const { Cities } = props
    return (
        <div className={styles.popularCities}>
            <div className="container">
                <h2 className={styles.title}>
                    {T.translate("Popular Destinations")}
                </h2>
                <section className={styles.contentWrapper + " row"}>
                    {!_isEmpty(Cities) && Cities.map((item, index) => (
                        <Link
                            to={(item.href && isT4Fes) ? item.href : `tours/${urlPrettify(item.name)}`}
                            key={index}
                        >
                            <div className={styles.destBox}>
                                <LazyLoad height={380} offset={100} once>
                                    <img
                                        className={StylesBase.cover_img}
                                        src={fixImageUrl(item.image, 158 * 2, 130 * 2, 55)}
                                        srcSet={
                                            `${fixImageUrl(item.image, 158, 130)} 1x, 
                                            ${fixImageUrl(item.image, 158 * 2, 130 * 2, 55)} 2x, 
                                            ${fixImageUrl(item.image, 158 * 3, 130 * 3, 55)} 3x`
                                        }
                                        sizes="(max-width: 100%) 100vw, 50vw"
                                        alt=""
                                    />
                                </LazyLoad>
                                <div className={styles.pop_box_overlay} />
                                <h3>{item.name}</h3>
                            </div>
                        </Link>
                    ))}
                </section>
            </div>
        </div>
    )
}

Component.propTypes = {
    Cities: PropTypes.oneOfType([PropTypes.array]).isRequired,
}

export default Component
